import { Props } from './IconSVG';

export function ArrowDownIcon(props: Props) {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path opacity="0.5" d="M1 1L5.5 5.5L10 1" stroke="white" strokeWidth="2" />
    </svg>
  );
}
