import Image from 'next/image';
import { RainbowConnectButton } from '@/components/RainbowConnectButton/RainbowConnectButton';

export function SocialConnect() {
  return (
    <div className="flex items-center gap-4 px-4 pb-3 md:gap-6 md:py-3">
      <a
        href="https://twitter.com/redstonexyz"
        className="text-[13px] uppercase sm:block"
        target="_blank"
        rel="noopener noreferrer"
      >
        Twitter
      </a>
      <a
        href="https://lattice.xyz/discord"
        className="[text-13px] uppercase sm:block"
        target="_blank"
        rel="noopener noreferrer"
      >
        Discord
      </a>

      <a
        className="shrink-0"
        href="https://github.com/latticexyz/redstone"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src="/icons/github.svg" alt="GitHub logo" width="24" height="24" />
      </a>

      <div className="grow md:hidden" />

      <div className="sm:block">
        <RainbowConnectButton />
      </div>
    </div>
  );
}
