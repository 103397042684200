type LogoProps = {
  color?: 'white' | 'black';
  width?: string;
  height?: string;
};

export function RhodoliteLogo({ color = 'white', width = '52', height = '52' }: LogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      shapeRendering="crispEdges"
      fill={color}
    >
      <path d="M0 5L0 0L5 0V5H0Z" fill="white" fillOpacity="0.25" />
      <path d="M0 35L0 40H5V35H0Z" fill="white" fillOpacity="0.25" />
      <path d="M40 5V0L35 0V5H40Z" fill="white" fillOpacity="0.25" />
      <path d="M40 35V40H35V35H40Z" fill="white" fillOpacity="0.25" />
      <path d="M0 10L0 5H5V10H0Z" fill="white" fillOpacity="0.25" />
      <path d="M0 30L0 35H5V30H0Z" fill="white" fillOpacity="0.25" />
      <path d="M40 10V5H35V10H40Z" fill="white" fillOpacity="0.25" />
      <path d="M40 30V35H35V30H40Z" fill="white" fillOpacity="0.25" />
      <path d="M20 35V30H25V35H20Z" fill="white" fillOpacity="0.5" />
      <path d="M25 35V30H30V35H25Z" fill="white" fillOpacity="0.5" />
      <path d="M15 35V30H20V35H15Z" fill="white" fillOpacity="0.5" />
      <path d="M10 35V30H15V35H10Z" fill="white" fillOpacity="0.5" />
      <path d="M5 25L10 25L10 20L5 20L5 25Z" fill="white" fillOpacity="0.5" />
      <path d="M5 30L10 30L10 25L5 25L5 30Z" fill="white" fillOpacity="0.5" />
      <path d="M5 35L10 35L10 30L5 30L5 35Z" fill="white" fillOpacity="0.5" />
      <path d="M35 35L30 35L30 30L35 30L35 35Z" fill="white" fillOpacity="0.5" />
      <path d="M10 15L15 15L15 10L10 10L10 15Z" fill="white" fillOpacity="0.5" />
      <path d="M5 15L10 15L10 10L5 10L5 15Z" fill="white" fillOpacity="0.5" />
      <path d="M10 10L15 10L15 5L10 5L10 10Z" fill="white" fillOpacity="0.5" />
      <path d="M15 10L20 10L20 5L15 5L15 10Z" fill="white" fillOpacity="0.5" />
      <path d="M20 10L25 10L25 5L20 5L20 10Z" fill="white" fillOpacity="0.5" />
      <path d="M5 10L10 10L10 5L5 5L5 10Z" fill="white" fillOpacity="0.5" />
      <path d="M30 15L25 15L25 10L30 10L30 15Z" fill="white" fillOpacity="0.5" />
      <path d="M35 15L30 15L30 10L35 10L35 15Z" fill="white" fillOpacity="0.5" />
      <path d="M30 10L25 10L25 5L30 5L30 10Z" fill="white" fillOpacity="0.5" />
      <path d="M35 10L30 10L30 5L35 5L35 10Z" fill="white" fillOpacity="0.5" />
      <path d="M5 5V0L10 0V5H5Z" fill="white" fillOpacity="0.25" />
      <path d="M5 35V40H10V35H5Z" fill="white" fillOpacity="0.25" />
      <path d="M35 5V0L30 0V5H35Z" fill="white" fillOpacity="0.25" />
      <path d="M35 35V40H30V35H35Z" fill="white" fillOpacity="0.25" />
      <path d="M5 20L10 20L10 15L5 15L5 20Z" fill="white" fillOpacity="0.5" />
      <path d="M35 25L30 25L30 20L35 20L35 25Z" fill="white" fillOpacity="0.5" />
      <path d="M35 20L30 20L30 15L35 15L35 20Z" fill="white" fillOpacity="0.5" />
      <path d="M35 30L30 30L30 25L35 25L35 30Z" fill="white" fillOpacity="0.5" />
      <path d="M10 10V15H15V10H10Z" fill="white" />
      <path d="M15 10V15H20V10H15Z" fill="white" />
      <path d="M20 10V15H25V10H20Z" fill="white" />
      <path d="M25 10V15H30V10H25Z" fill="white" />
      <path d="M10 15V20H15V15H10Z" fill="white" />
      <path d="M25 15V20H30V15H25Z" fill="white" />
      <path d="M10 20V25H15V20H10Z" fill="white" />
      <path d="M25 20V25H30V20H25Z" fill="white" />
      <path d="M10 25V30H15V25H10Z" fill="white" />
      <path d="M25 25V30H30V25H25Z" fill="white" />
      <path d="M20 25V30H25V25H20Z" fill="white" />
      <path d="M15 25V30H20V25H15Z" fill="white" />
    </svg>
  );
}
