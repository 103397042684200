import clsx from 'clsx';

const variants = {
  primary:
    'bg-primary disabled:bg-[#5C1F1F] disabled:text-white/30 font-mono flex items-center justify-center bg-primary px-6 py-3 uppercase',
  secondary: 'bg-[#32353D] border-neutral-400 text-white ',
};

export type BridgeButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  children: string;
  variant?: 'primary' | 'secondary';
};

export function BridgeButton({
  onClick,
  disabled,
  children,
  className,
  variant = 'primary',
}: BridgeButtonProps) {
  return (
    <button
      type="button"
      className={clsx(
        className ?? 'text-md flex items-center justify-center p-4 font-mono uppercase',
        variants[variant],
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
