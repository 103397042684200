import { cn } from '@/utils/cn';
import Link from 'next/link';
import { ReactNode } from 'react';

type NavLinkProps = {
  href: string;
  children: ReactNode;
  active?: boolean;
  className?: string;
  target?: string;
};

export function NavLink({ href, children, active, className, target }: NavLinkProps) {
  return (
    <Link
      href={href}
      className={cn(
        'block px-4 py-3 font-mono text-lg uppercase text-white/70 md:text-sm',
        {
          'bg-white/10 text-white': active,
          'text-white/70': !active,
        },
        'transition hover:bg-white/10 hover:text-white',
        className,
      )}
      target={target ?? '_self'}
    >
      {children}
    </Link>
  );
}
