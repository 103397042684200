type LogoProps = {
  color?: 'white' | 'black';
  width?: string;
  height?: string;
};

export function GarnetLogo({ color = 'white', width = '52', height = '52' }: LogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      shapeRendering="crispEdges"
      fill={color}
    >
      <path d="M10 5V10H15V5H10Z" fill="white" fillOpacity="0.5" />
      <path d="M5 5V10H10V5H5Z" fill="white" fillOpacity="0.5" />
      <path d="M10 10V15H15V10H10Z" fill="white" />
      <path d="M15 10V15H20V10H15Z" fill="white" />
      <path d="M20 10V15H25V10H20Z" fill="white" />
      <path d="M25 10V15H30V10H25Z" fill="white" />
      <path d="M10 15V20H15V15H10Z" fill="white" />
      <path d="M25 15V20H30V15H25Z" fill="white" />
      <path d="M10 20V25H15V20H10Z" fill="white" />
      <path d="M25 20V25H30V20H25Z" fill="white" />
      <path d="M10 25V30H15V25H10Z" fill="white" />
      <path d="M25 25V30H30V25H25Z" fill="white" />
      <path d="M20 25V30H25V25H20Z" fill="white" />
      <path d="M15 25V30H20V25H15Z" fill="white" />
      <path d="M5 10V15H10V10H5Z" fill="white" fillOpacity="0.5" />
      <path d="M20 5V10H25V5H20Z" fill="white" fillOpacity="0.5" />
      <path d="M25 5V10H30V5H25Z" fill="white" fillOpacity="0.5" />
      <path d="M30 5V10H35V5H30Z" fill="white" fillOpacity="0.5" />
      <path d="M15 5V10H20V5H15Z" fill="white" fillOpacity="0.5" />
      <path d="M10 40V35H15V40H10Z" fill="white" fillOpacity="0.25" />
      <path d="M10 5V0L15 0V5H10Z" fill="white" fillOpacity="0.25" />
      <path d="M20 40V35H25V40H20Z" fill="white" fillOpacity="0.25" />
      <path d="M20 5V0L25 0V5H20Z" fill="white" fillOpacity="0.25" />
      <path d="M20 35V30H25V35H20Z" fill="white" fillOpacity="0.5" />
      <path d="M25 35V30H30V35H25Z" fill="white" fillOpacity="0.5" />
      <path d="M15 40V35H20V40H15Z" fill="white" fillOpacity="0.25" />
      <path d="M15 5V0L20 0V5H15Z" fill="white" fillOpacity="0.25" />
      <path d="M25 40V35H30V40H25Z" fill="white" fillOpacity="0.25" />
      <path d="M25 5V0L30 0V5H25Z" fill="white" fillOpacity="0.25" />
      <path d="M15 35V30H20V35H15Z" fill="white" fillOpacity="0.5" />
      <path d="M10 35V30H15V35H10Z" fill="white" fillOpacity="0.5" />
      <path d="M0 15L5 15L5 20L-2.18557e-07 20L0 15Z" fill="white" fillOpacity="0.25" />
      <path d="M0 10L5 10L5 15L-2.18557e-07 15L0 10Z" fill="white" fillOpacity="0.25" />
      <path d="M5 20L10 20L10 25L5 25L5 20Z" fill="white" fillOpacity="0.5" />
      <path d="M5 25L10 25L10 30L5 30L5 25Z" fill="white" fillOpacity="0.5" />
      <path d="M5 30L10 30L10 35L5 35L5 30Z" fill="white" fillOpacity="0.5" />
      <path d="M0 20L5 20L5 25L-2.18557e-07 25L0 20Z" fill="white" fillOpacity="0.25" />
      <path d="M0 25L5 25L5 30L-2.18557e-07 30L0 25Z" fill="white" fillOpacity="0.25" />
      <path d="M5 15L10 15L10 20L5 20L5 15Z" fill="white" fillOpacity="0.5" />
      <path d="M40 10L35 10L35 15L40 15L40 10Z" fill="white" fillOpacity="0.25" />
      <path d="M40 20L35 20L35 25L40 25L40 20Z" fill="white" fillOpacity="0.25" />
      <path d="M35 10L30 10L30 15L35 15L35 10Z" fill="white" fillOpacity="0.5" />
      <path d="M35 20L30 20L30 25L35 25L35 20Z" fill="white" fillOpacity="0.5" />
      <path d="M35 25L30 25L30 30L35 30L35 25Z" fill="white" fillOpacity="0.5" />
      <path d="M35 30L30 30L30 35L35 35L35 30Z" fill="white" fillOpacity="0.5" />
      <path d="M40 15L35 15L35 20L40 20L40 15Z" fill="white" fillOpacity="0.25" />
      <path d="M40 25L35 25L35 30L40 30L40 25Z" fill="white" fillOpacity="0.25" />
      <path d="M35 15L30 15L30 20L35 20L35 15Z" fill="white" fillOpacity="0.5" />
    </svg>
  );
}
