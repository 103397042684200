import { IconSVG, Props } from './IconSVG';

export function DotsIcon(props: Props) {
  return (
    <IconSVG {...props}>
      <path
        d="M9 3.75L9 3.7575M9 9L9 9.0075M9 14.25L9 14.2575M9 4.5C8.58579 4.5 8.25 4.16421 8.25 3.75C8.25 3.33579 8.58579 3 9 3C9.41421 3 9.75 3.33579 9.75 3.75C9.75 4.16421 9.41421 4.5 9 4.5ZM9 9.75C8.58579 9.75 8.25 9.41421 8.25 9C8.25 8.58579 8.58579 8.25 9 8.25C9.41421 8.25 9.75 8.58579 9.75 9C9.75 9.41421 9.41421 9.75 9 9.75ZM9 15C8.58579 15 8.25 14.6642 8.25 14.25C8.25 13.8358 8.58579 13.5 9 13.5C9.41421 13.5 9.75 13.8358 9.75 14.25C9.75 14.6642 9.41421 15 9 15Z"
        stroke="#A3A3A3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconSVG>
  );
}
