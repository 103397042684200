type LogoProps = {
  color?: 'white' | 'black';
  width?: string;
  height?: string;
};

export function Logo({ color = 'white', width = '52', height = '52' }: LogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 52 52"
      shapeRendering="crispEdges"
      fill={color}
    >
      <path opacity="0.75" d="M13 19.5L13 13L19.5 13L19.5 19.5L13 19.5Z" />
      <path d="M13 26L13 19.5L19.5 19.5L19.5 26L13 26Z" />
      <path d="M13 32.5L13 26L19.5 26L19.5 32.5L13 32.5Z" />
      <path opacity="0.75" d="M13 39L13 32.5L19.5 32.5L19.5 39L13 39Z" />
      <path opacity="0.75" d="M32.5 19.5L32.5 13L39 13L39 19.5L32.5 19.5Z" />
      <path d="M32.5 26L32.5 19.5L39 19.5L39 26L32.5 26Z" />
      <path d="M32.5 32.5L32.5 26L39 26L39 32.5L32.5 32.5Z" />
      <path opacity="0.75" d="M32.5 39L32.5 32.5L39 32.5L39 39L32.5 39Z" />
      <path d="M26 39H19.5V32.5H26V39Z" />
      <path d="M32.5 39H26V32.5H32.5V39Z" />
      <path d="M26 19.5H19.5V13H26V19.5Z" />
      <path d="M32.5 19.5H26V13H32.5V19.5Z" />
      <path opacity="0.25" d="M19.5 0V6.5H26V0H19.5Z" />
      <path opacity="0.75" d="M26 45.5V39H32.5V45.5H26Z" />
      <path opacity="0.75" d="M45.5 26L39 26L39 32.5L45.5 32.5L45.5 26Z" />
      <path opacity="0.75" d="M6.5 26L13 26L13 32.5L6.5 32.5L6.5 26Z" />
      <path opacity="0.75" d="M26 6.5V13H32.5V6.5H26Z" />
      <path opacity="0.25" d="M26 0V6.5H32.5V0H26Z" />
      <path opacity="0.25" d="M19.5 52V45.5H26V52H19.5Z" />
      <path opacity="0.25" d="M26 52V45.5H32.5V52H26Z" />
      <path opacity="0.25" d="M52 19.5L45.5 19.5L45.5 26L52 26L52 19.5Z" />
      <path opacity="0.25" d="M52 26L45.5 26L45.5 32.5L52 32.5L52 26Z" />
      <path opacity="0.25" d="M0 26L6.5 26L6.5 32.5L2.84124e-07 32.5L0 26Z" />
      <path opacity="0.25" d="M0 19.5L6.5 19.5L6.5 26L2.84124e-07 26L0 19.5Z" />
      <path opacity="0.75" d="M19.5 6.5V13H26V6.5H19.5Z" />
      <path opacity="0.75" d="M19.5 45.5V39H26V45.5H19.5Z" />
      <path opacity="0.75" d="M45.5 19.5L39 19.5L39 26L45.5 26L45.5 19.5Z" />
      <path opacity="0.75" d="M6.5 19.5L13 19.5L13 26L6.5 26L6.5 19.5Z" />
    </svg>
  );
}
