import { Chain } from 'viem';
import { holesky } from 'viem/chains';
import { garnetHolesky, redstoneHolesky, redstone, rhodolite } from './chains/chains';

type ChainMetadata = {
  summary: {
    location: string;
    svg: string;
  };
  svg: string;
  description: string;
  iconUrl?: string;
};

export default [
  {
    ...redstone,
    summary: {
      location: 'Redstone',
      svg: '/icons/eth-summary.svg',
    },
    svg: '/icons/redstone.svg',
    description: 'Redstone',
    testnet: true,
  },
  {
    ...redstoneHolesky,
    summary: {
      location: 'Holesky',
      svg: '/icons/eth-summary.svg',
    },
    svg: '/icons/redstone.svg',
    description: 'Redstone Holesky',
    iconUrl:
      'https://images.ctfassets.net/q5ulk4bp65r7/3TBS4oVkD1ghowTqVQJlqj/2dfd4ea3b623a7c0d8deb2ff445dee9e/Consumer_Wordmark.svg',
    testnet: true,
  },
  {
    ...garnetHolesky,
    summary: {
      location: 'Garnet Holesky',
      svg: '/icons/eth-summary.svg',
    },
    svg: '/icons/redstone.svg',
    description: 'Garnet Holesky',
    testnet: true,
  },
  {
    ...rhodolite,
    summary: {
      location: 'Rhodolite',
      svg: '/icons/eth-summary.svg',
    },
    svg: '/icons/redstone.svg',
    description: 'Rhodolite',
    testnet: true,
  },
  {
    ...holesky,
    summary: {
      location: 'Holesky',
      svg: '/icons/eth.svg',
    },
    svg: '/icons/eth.svg',
    description: 'Holesky',
    iconUrl:
      'https://images.ctfassets.net/q5ulk4bp65r7/3TBS4oVkD1ghowTqVQJlqj/2dfd4ea3b623a7c0d8deb2ff445dee9e/Consumer_Wordmark.svg',
    blockExplorers: {
      etherscan: {
        name: 'Etherscan',
        url: 'https://holesky.etherscan.io',
      },
      default: {
        name: 'Blockscout',
        url: 'https://eth-holesky.blockscout.com',
      },
    },
    rpcUrls: {
      ...holesky.rpcUrls,
      default: {
        http: [
          'https://chaotic-compatible-scion.ethereum-holesky.quiknode.pro/da805e1c6961a69c45d9d2652c2bbb274d02f760',
        ],
        webSocket: [
          'https://chaotic-compatible-scion.ethereum-holesky.quiknode.pro/da805e1c6961a69c45d9d2652c2bbb274d02f760',
        ],
      },
    },
  },
  {
    id: 888,
    name: 'Quarry Devnet',
    network: 'quarry-devnet',
    summary: {
      location: 'Quarry Devnet',
      svg: '/icons/eth-summary.svg',
    },
    svg: '/icons/redstone.svg',
    description: 'Quarry Devnet',
    iconUrl:
      'https://images.ctfassets.net/q5ulk4bp65r7/3TBS4oVkD1ghowTqVQJlqj/2dfd4ea3b623a7c0d8deb2ff445dee9e/Consumer_Wordmark.svg',
    nativeCurrency: {
      decimals: 18,
      name: 'Quarry Ether',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: {
        http: ['https://l1.quarry.linfra.xyz'],
        webSocket: ['wss://l1.quarry.linfra.xyz'],
      },
      public: {
        http: ['https://l1.quarry.linfra.xyz'],
        webSocket: ['wss://l1.quarry.linfra.xyz'],
      },
    },
    blockExplorers: {
      default: {
        name: 'Blockscout',
        url: 'https://l1-explorer.quarry.linfra.xyz',
      },
    },
    testnet: true,
  },
  {
    id: 894,
    name: 'Redstone Devnet 894',
    network: 'redstone-894',
    summary: {
      location: 'Quarry Devnet',
      svg: '/icons/eth-summary.svg',
    },
    svg: '/icons/redstone.svg',
    description: 'Redstone Devnet',
    iconUrl:
      'https://images.ctfassets.net/q5ulk4bp65r7/3TBS4oVkD1ghowTqVQJlqj/2dfd4ea3b623a7c0d8deb2ff445dee9e/Consumer_Wordmark.svg',
    nativeCurrency: {
      decimals: 18,
      name: 'Redstone Ether',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: {
        http: ['https://894-follower.quarry.linfra.xyz'],
        webSocket: ['wss://894-follower.quarry.linfra.xyz'],
      },
      public: {
        http: ['https://894-follower.quarry.linfra.xyz'],
        webSocket: ['wss://894-follower.quarry.linfra.xyz'],
      },
    },
    blockExplorers: {
      default: {
        name: 'Blockscout',
        url: 'https://894-explorer.quarry.linfra.xyz',
      },
    },
    testnet: true,
  },
  {
    id: 1,
    name: 'Ethereum',
    network: 'homestead',
    summary: {
      location: 'Ethereum',
      svg: '/icons/eth.svg',
    },
    svg: '/icons/eth.svg',
    description: 'Ethereum',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: {
      default: {
        http: ['https://ethereum.publicnode.com'],
      },
      public: {
        http: ['https://ethereum.publicnode.com'],
      },
    },
    blockExplorers: {
      etherscan: {
        name: 'Etherscan',
        url: 'https://etherscan.io',
      },
      default: {
        name: 'Etherscan',
        url: 'https://etherscan.io',
      },
    },
    contracts: {
      ensRegistry: {
        address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
      },
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 14353601,
      },
    },
  },
  {
    id: 5,
    network: 'goerli',
    name: 'Goerli',
    summary: {
      location: 'Goerli',
      svg: '/icons/eth.svg',
    },
    svg: '/icons/eth.svg',
    description: 'Ethereum',
    nativeCurrency: {
      name: 'Goerli Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: {
      alchemy: {
        http: ['https://eth-goerli.g.alchemy.com/v2'],
        webSocket: ['wss://eth-goerli.g.alchemy.com/v2'],
      },
      infura: {
        http: ['https://goerli.infura.io/v3'],
        webSocket: ['wss://goerli.infura.io/ws/v3'],
      },
      default: { http: ['https://rpc.ankr.com/eth_goerli'] },
      public: { http: ['https://rpc.ankr.com/eth_goerli'] },
    },
    blockExplorers: {
      etherscan: {
        name: 'Etherscan',
        url: 'https://goerli.etherscan.io',
      },
      default: {
        name: 'Etherscan',
        url: 'https://goerli.etherscan.io',
      },
    },
    contracts: {
      ensRegistry: {
        address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
      },
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 6507670,
      },
    },
    testnet: true,
  },
  {
    id: 11155111,
    network: 'sepolia',
    name: 'Sepolia',
    summary: {
      location: 'Sepolia',
      svg: '/icons/eth.svg',
    },
    svg: '/icons/eth.svg',
    description: 'Ethereum',
    nativeCurrency: {
      name: 'Sepolia Ether',
      symbol: 'SEP',
      decimals: 18,
    },
    rpcUrls: {
      alchemy: {
        http: ['https://eth-sepolia.g.alchemy.com/v2'],
        webSocket: ['wss://eth-sepolia.g.alchemy.com/v2'],
      },
      infura: {
        http: ['https://sepolia.infura.io/v3'],
        webSocket: ['wss://sepolia.infura.io/ws/v3'],
      },
      default: {
        http: ['https://ethereum-sepolia.publicnode.com'],
      },
      public: {
        http: ['https://ethereum-sepolia.publicnode.com'],
      },
    },
    blockExplorers: {
      etherscan: {
        name: 'Etherscan',
        url: 'https://sepolia.etherscan.io',
      },
      default: {
        name: 'Etherscan',
        url: 'https://sepolia.etherscan.io',
      },
    },
    contracts: {
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 6507670,
      },
    },
    testnet: true,
  },
] satisfies (Chain & ChainMetadata)[];
