import { ReactElement, useEffect } from 'react';
import { OFACModal } from '@/components/OFACModal/OFACModal';
import { TOSModal } from '@/components/TOSModal/TOSModal';
import { useOFACStatus } from '@/contexts/OFACContext';
import { useTOSStatus } from '@/contexts/TOSContext';
import { useChainEnv } from '@/utils/hooks/useChainEnv';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';

type LayoutProps = { children: ReactElement };

export function Layout({ children }: LayoutProps) {
  const { pathname } = useRouter();
  const { isConnected } = useAccount();

  const chainEnv = useChainEnv();
  const isMainnet = chainEnv === 'mainnet';

  const { isOFACAllowed, isOFACAllowedLoading } = useOFACStatus();

  const { isTosAccepted, tosRegion, isTosRegionLoading } = useTOSStatus();
  const { isOpen: isTosModalOpen, onOpen: onOpenTosModal } = useDisclosure();

  useEffect(() => {
    if (!isTosAccepted) {
      onOpenTosModal();
    }
  }, [isTosAccepted, onOpenTosModal, isMainnet]);

  const tosRequiredPath =
    pathname === '/deposit' || pathname === '/withdraw' || pathname === '/transactions';
  const isOFACRequiredPath = pathname !== '/transactions';

  return (
    <>
      {isMainnet && !isOFACAllowed && !isOFACAllowedLoading && isOFACRequiredPath && (
        <OFACModal isOpen />
      )}

      {tosRequiredPath &&
        isTosModalOpen &&
        !isTosAccepted &&
        isOFACAllowed &&
        !isTosRegionLoading &&
        isConnected && <TOSModal isOpen={isTosModalOpen} tosRegion={tosRegion} />}
      {children}
    </>
  );
}
