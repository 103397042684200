/* eslint-disable jsx-a11y/anchor-is-valid */
import { Popover } from '@headlessui/react';
import { useRouter } from 'next/router';

export function Header() {
  const { pathname } = useRouter();

  return (
    <Popover className="relative">
      <header className="px-8 py-5">
        <nav className="flex flex-row items-center sm:justify-between">
          <h2 className="grow	text-center font-mono text-2xl uppercase text-white sm:grow-0">
            {pathname === '/transactions' ? 'Transactions' : 'Bridge'}
          </h2>
        </nav>
      </header>
    </Popover>
  );
}
