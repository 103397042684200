import './global.css';
import '@rainbow-me/rainbowkit/styles.css';

import { QueryClient, QueryClientProvider } from 'react-query';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { Header } from '@/components/Header/Header';
import { Layout } from '@/components/Layout/Layout';
import { OFACProvider } from '@/contexts/OFACContext';
import { TOSProvider } from '@/contexts/TOSContext';
import { connectWallet } from '@/wallet/connect';
import App, { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { WagmiConfig } from 'wagmi';
import { PageContainer } from '@/ui/PageContainer';
import { fontBasierCircle, fontPPSupplyMono } from '@/fonts';
import { redstone } from '@/chains/redstone';
import { garnetHolesky } from '@/chains/garnetHolesky';
import { rhodolite } from '@/chains/rhodolite';
import { redstoneHolesky } from '@/chains/redstoneHolesky';

const { publicRuntimeConfig } = getConfig();

export type ChainId = keyof typeof primaryColor;

export const activeChainId: ChainId = process.env.NEXT_PUBLIC_L2_CHAIN_ID
  ? (Number(process.env.NEXT_PUBLIC_L2_CHAIN_ID) as ChainId)
  : redstone.id;

const primaryColor = {
  [redstone.id]: 'var(--color-redstone)',
  [garnetHolesky.id]: 'var(--color-garnet-holesky)',
  [rhodolite.id]: 'var(--color-rhodolite)',
  [redstoneHolesky.id]: 'var(--color-rhodolite)',
} as const;

// https://nextjs.org/docs/advanced-features/custom-app
export async function getInitialProps(context: AppContext) {
  const appProps = await App.getInitialProps(context);

  return appProps;
}

const queryClient = new QueryClient();

const allowedPaths = new Set(['/withdraw', '/deposit', '/transactions']);

function Root({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const { ...props } = pageProps as { props: unknown };
  const { chains: providerChains, wagmiConfig } = connectWallet([
    parseInt(publicRuntimeConfig.l1ChainID),
    parseInt(publicRuntimeConfig.l2ChainID),
  ]);

  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>
        {`
          :root {
            --font-supply-mono: ${fontPPSupplyMono.style.fontFamily};
            --font-basier-circle: ${fontBasierCircle.style.fontFamily};
            --color-redstone: #f34242;
            --color-garnet-holesky: #a91f2f;
            --color-rhodolite: #6d2436;
            --primary-color: ${primaryColor[activeChainId]};
          }
        `}
      </style>
      <QueryClientProvider client={queryClient}>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider chains={providerChains} modalSize="compact">
            <TOSProvider>
              <OFACProvider>
                <PageContainer>
                  <Layout>
                    <div>
                      {(pathname === '/' || !allowedPaths.has(pathname)) && (
                        <Component {...props} />
                      )}
                      {allowedPaths.has(pathname) && (
                        <>
                          <Header />
                          <div className="m-0 w-full p-0 sm:h-[calc(100vh-72px)]">
                            <Component {...props} />
                          </div>
                        </>
                      )}
                    </div>
                  </Layout>
                </PageContainer>
              </OFACProvider>
            </TOSProvider>
          </RainbowKitProvider>
        </WagmiConfig>
      </QueryClientProvider>
    </>
  );
}

Root.getInitialProps = getInitialProps;

export default Root;
